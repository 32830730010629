import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["writingTone", "writingLevel"]

  toggleWritingFields(event) {
    const hasProfile = event.target.value !== ""
    
    this.writingToneTarget.disabled = hasProfile
    this.writingLevelTarget.disabled = hasProfile
    
    if (hasProfile) {
      this.writingToneTarget.required = false
      this.writingLevelTarget.required = false
    } else {
      this.writingToneTarget.required = true
      this.writingLevelTarget.required = true
    }
  }
} 