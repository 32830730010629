import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["resourceInstructions", "resourceSelect", "newResources"]

  connect() {
    console.log("Virtual Writer Form controller connected")
    this.updateResourceInstructionsRequirement()
  }

  // Make resource instructions required if resources are selected or files are uploaded
  updateResourceInstructionsRequirement() {
    const hasSelectedResources = this.resourceSelectTarget.value.length > 0
    const hasUploadedFiles = this.newResourcesTarget.files.length > 0
    
    if (hasSelectedResources || hasUploadedFiles) {
      this.resourceInstructionsTarget.required = true
    } else {
      this.resourceInstructionsTarget.required = false
    }
  }

  // Called when resources are selected from dropdown
  resourceSelected() {
    this.updateResourceInstructionsRequirement()
  }

  // Called when files are selected for upload
  filesSelected() {
    this.updateResourceInstructionsRequirement()
  }
} 