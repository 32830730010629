import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    $(this.element).select2({
      width: '100%',
      theme: 'classic',
      allowClear: true,
      placeholder: 'Select a resource',
      minimumResultsForSearch: 0  // This ensures search is always shown
    });
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
} 